<template>
  <div>
    <div ref="recaptcha" class="g-recaptcha" :data-sitekey="siteKey" :data-callback="onVerify" />
  </div>
</template>

<script>
  export default {
    props: {
      siteKey: {
        type: String,
        required: true,
      },
      onVerify: {
        type: Function,
        default: () => {},
      },
      onExpired: {
        type: Function,
        default: () => {},
      },
      onLoad: {
        type: Function,
        default: () => {},
      },
      onError: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        recaptchaInstance: null,
      };
    },
    methods: {
      onScriptLoaded() {
        if (window.grecaptcha) {
          this.initReCapt();
        } else {
          this.onError();
          console.error("El script de reCAPTCHA no se cargó correctamente.");
        }
      },
      initReCapt() {
        try {
          this.recaptchaInstance = window.grecaptcha.render(this.$refs.recaptcha, {
            sitekey: this.siteKey,
            callback: this.onVerify,
            "expired-callback": () => this.onExpired(false),
          });
          this.onLoad();
        } catch (error) {
          this.onError(error);
        }
      },
      resetReCaptcha() {
        if (window.grecaptcha && this.recaptchaInstance !== null) {
          window.grecaptcha.reset(this.recaptchaInstance);
        }
      },
    },
    mounted() {
      if (window.grecaptcha) {
        this.initReCapt();
      } else {
        this.onError(error);
      }
    },
  };
</script>
